import { rgbToHex, styleVars } from '@/compositions/styleVariables'
import { getColorObject } from '@/services/charts/chart'

export const getSmsCampaignsTimelineChartData = (week: boolean): Record<string, any> => ({
  labels: week
    ? ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug']
    : ['6am', '8am', '10am', '12pm', '2pm', '4pm', '6pm', '8pm'],
  datasets: [
    {
      label: 'Responses',
      data: week ? [174, 240, 333, 150, 278, 301, 361, 210] : [20, 25, 43, 30, 16, 70, 77, 40],
      ...getColorObject(styleVars.blue500),
    },
    {
      label: 'Clicks',
      data: week ? [240, 270, 384, 190, 378, 401, 421, 290] : [40, 33, 50, 44, 31, 85, 90, 55],
      ...getColorObject(styleVars.green500),
    },
  ],
})

export const getEmailCampaignsTimelineChartData = (week: boolean): Record<string, any> => ({
  labels: week
    ? ['7 Aug', '8 Aug', '9 Aug', '10 Aug', '11 Aug', '12 Aug', '13 Aug', '14 Aug']
    : ['6am', '8am', '10am', '12pm', '2pm', '4pm', '6pm', '8pm'],
  datasets: [
    {
      label: 'Open',
      data: week ? [340, 310, 584, 220, 448, 501, 521, 380] : [50, 63, 90, 64, 55, 115, 130, 75],
      ...getColorObject(styleVars.blue500),
    },
    {
      label: 'Clicks',
      data: week ? [240, 270, 384, 190, 378, 401, 421, 290] : [40, 33, 50, 44, 31, 85, 90, 55],
      ...getColorObject(styleVars.green500),
    },
    {
      label: 'Responses',
      data: week ? [174, 240, 333, 150, 278, 301, 361, 210] : [20, 25, 43, 30, 16, 70, 77, 40],
      ...getColorObject(styleVars.orange500),
    },
  ],
})

export const getCampaignsSmsGeographyChartData = (): Record<string, any> => ({
  labels: [
    'United States',
    'Thailand',
  ],
  datasets: [{
    data: [
      {
        value: 7210,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'us',
          name: 'United States',
        },
      },
      {
        value: 2510,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'th',
          name: 'Thailand',
        },
      },
    ],
    backgroundColor: [
      rgbToHex(styleVars.orange300),
      rgbToHex(styleVars.mint300),
    ],
    hoverBackgroundColor: [
      rgbToHex(styleVars.orange300),
      rgbToHex(styleVars.mint300),
    ],
  }],
})

export const getCampaignsEmailGeographyChartData = (opens: boolean): Record<string, any> => ({
  labels: [
    'United States',
    'Canada',
    'United Kingdom',
    'France',
    'Germany',
  ],
  datasets: [{
    data: [
      {
        value: opens ? 3210 : 2475,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'us',
          name: 'United States',
        },
      },
      {
        value: opens ? 2291 : 1944,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'ca',
          name: 'Canada',
        },
      },
      {
        value: opens ? 1827 : 1222,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'uk',
          name: 'United Kingdom',
        },
      },
      {
        value: opens ? 1410 : 977,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'fr',
          name: 'France',
        },
      },
      {
        value: opens ? 698 : 455,
        tooltipValueLabel: 'Delivered',
        tooltipPercentLabel: '% of all deliveries',
        country: {
          id: 'de',
          name: 'Germany',
        },
      },
    ],
    backgroundColor: [
      rgbToHex(styleVars.blue400),
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.crimson500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.mint500),
    ],
    hoverBackgroundColor: [
      rgbToHex(styleVars.blue400),
      rgbToHex(styleVars.green500),
      rgbToHex(styleVars.crimson500),
      rgbToHex(styleVars.orange500),
      rgbToHex(styleVars.mint500),
    ],
  }],
})
